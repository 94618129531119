import { Button } from '@/components/ui/button';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/cn';
import { toMoneyString } from '@/lib/utils';
import type { BaseInstance } from '@pigello/pigello-matrix';
import { useState } from 'react';
import type { Path } from 'react-hook-form';
import type { FieldProps } from '../types';
import { numberregexp, parseFieldValue } from '../utils';
import { DescriptionTooltip } from './description-tooltip';

const TO_EXTERNAL_CALCULATION_METHODS: Record<
  number,
  (val: number, area: number | undefined) => number
> = {
  1: (val: number) => val / 12, //YEARLY:1,
  2: (val: number, area: number | undefined) => (val * (area ?? 1)) / 12, //SQM_YEARLY:2,
  3: (val: number) => val, //MONTHLY:3,
  4: (val: number, area: number | undefined) => val * (area ?? 1), //SQM_MONTHLY:4
};

const TO_INTERNAL_CALCULATION_METHODS: Record<
  number,
  (val: number, area: number | undefined) => number
> = {
  1: (val: number) => val * 12, //YEARLY:1,
  2: (val: number, area: number | undefined) => (val / (area ?? 1)) * 12, //SQM_YEARLY:2,
  3: (val: number) => val, //MONTHLY:3,
  4: (val: number, area: number | undefined) => val / (area ?? 1), //SQM_MONTHLY:4
};

export function PriceField<Instance extends BaseInstance = BaseInstance>({
  formField,
  name,
  placeholder,
  label,
  description,
  className,
  horizontal,
  disabled,
  canHandleField,
  area,
}: FieldProps<Instance> & { area?: number }) {
  const [mode, setMode] =
    useState<keyof typeof TO_INTERNAL_CALCULATION_METHODS>(3);

  const [hasSetInitialValue, setHasSetInitialValue] = useState(false);
  const [currentValue, setCurrentValue] = useState<string | null>(null);

  const renderInfo = ({
    mode,
    currentValue,
    actualValue,
  }: {
    mode: keyof typeof TO_INTERNAL_CALCULATION_METHODS;
    currentValue: string | null;
    actualValue: number | null;
  }) => {
    if (
      currentValue == null ||
      actualValue == null ||
      actualValue.toFixed(2) === 'NaN'
    )
      return null;

    switch (mode) {
      case 1:
        return (
          <div className='text-xs text-muted-foreground'>
            {toMoneyString(parseFloat(actualValue.toFixed(2)))}/månad,{' '}
            {toMoneyString(parseFloat((actualValue * 12).toFixed(2)))}/år
          </div>
        );
      case 2:
        return (
          <div className='text-xs text-muted-foreground'>
            {toMoneyString(parseFloat(actualValue.toFixed(2)))}/månad,{' '}
            {toMoneyString(parseFloat((actualValue * 12).toFixed(2)))}/år
          </div>
        );
      case 3:
        return (
          <div className='text-xs text-muted-foreground'>
            {toMoneyString(parseFloat(actualValue.toFixed(2)))}/månad,{' '}
            {toMoneyString(parseFloat((actualValue * 12).toFixed(2)))}/år
          </div>
        );
      case 4:
        return (
          <div className='text-xs text-muted-foreground'>
            {toMoneyString(parseFloat(actualValue.toFixed(2)))}/månad,{' '}
            {toMoneyString(parseFloat((actualValue * 12).toFixed(2)))}/år
          </div>
        );
    }
  };

  return (
    <FormField
      rules={{
        required: formField.required && 'Detta fält är obligatoriskt',
        maxLength: {
          value: 20,
          message: 'Fältet får inte vara längre än 20 tecken',
        },
      }}
      control={formField.control}
      name={name as Path<Instance>}
      render={({ field }) => {
        if (!hasSetInitialValue) {
          if (field?.value && field.value != currentValue) {
            setHasSetInitialValue(true);
            setCurrentValue(field.value.toString());
          }
        }

        const setExternalValue = (
          parsed: string | null,
          overrideMode?: keyof typeof TO_INTERNAL_CALCULATION_METHODS
        ) => {
          if (parsed) {
            const externalValue = TO_EXTERNAL_CALCULATION_METHODS[
              overrideMode ?? mode
            ](parseFloat(parsed), area);

            field.onChange(externalValue);
          } else {
            field.onChange(null);
          }
        };

        const onUpdate = (
          internal: string | null,
          overrideMode?: keyof typeof TO_INTERNAL_CALCULATION_METHODS
        ) => {
          const parsed = parseFieldValue({
            newValue: internal,
            prevValue: currentValue,
            regex: numberregexp,
          });

          setCurrentValue(parsed);

          setExternalValue(parsed, overrideMode);
        };

        if (horizontal) {
          return (
            <FormItem
              className={cn('flex items-center justify-between', className)}
            >
              <div className='w-1/2 shrink-0'>
                <div className='flex items-center'>
                  <FormLabel>
                    {label ?? formField.label}{' '}
                    {formField.required && <span>*</span>}
                  </FormLabel>
                  {(description || formField.description) && (
                    <DescriptionTooltip
                      description={description ?? formField.description}
                    />
                  )}
                </div>

                <FormMessage />
              </div>
              <FormControl>
                <div className='grid gap-1'>
                  <div className='flex items-center '>
                    <Input
                      className='rounded-r-none'
                      {...field}
                      onChange={(e) => onUpdate(e.target.value)}
                      value={currentValue ?? ''}
                      placeholder={placeholder ?? formField.label}
                      disabled={disabled ?? field.disabled ?? !canHandleField}
                    />

                    {area != null && (
                      <Button
                        disabled={disabled ?? field.disabled ?? !canHandleField}
                        variant='outline'
                        className={cn(
                          'w-[60px] min-w-[60px] max-w-[60px] whitespace-nowrap rounded-none  py-[13px] text-xs !leading-none ',
                          {
                            'opacity-50': [1, 3].includes(mode),
                          }
                        )}
                        type='button'
                        onClick={() => {
                          switch (mode) {
                            case 1:
                              setMode(2);
                              onUpdate(currentValue, 2);
                              break;
                            case 2:
                              setMode(1);
                              onUpdate(currentValue, 1);
                              break;
                            case 3:
                              setMode(4);
                              onUpdate(currentValue, 4);
                              break;
                            case 4:
                              setMode(3);
                              onUpdate(currentValue, 3);
                              break;
                          }
                        }}
                      >
                        /{area}m2
                      </Button>
                    )}
                    <Button
                      disabled={disabled ?? field.disabled ?? !canHandleField}
                      variant='secondary'
                      className='w-[60px] min-w-[60px] max-w-[60px] whitespace-nowrap rounded-sm rounded-l-none border border-input py-[13px]  text-xs !leading-none '
                      type='button'
                      onClick={() => {
                        switch (mode) {
                          case 1:
                            setMode(3);
                            onUpdate(currentValue, 3);
                            break;
                          case 2:
                            setMode(4);
                            onUpdate(currentValue, 4);
                            break;
                          case 3:
                            setMode(1);
                            onUpdate(currentValue, 1);
                            break;
                          case 4:
                            setMode(2);
                            onUpdate(currentValue, 2);
                            break;
                        }
                      }}
                    >
                      {[1, 2].includes(mode) ? '/år' : '/mån'}
                    </Button>
                  </div>
                  {renderInfo({ mode, currentValue, actualValue: field.value })}
                </div>
              </FormControl>
            </FormItem>
          );
        } else {
          return (
            <FormItem className={className}>
              <div className='flex items-center'>
                <FormLabel>
                  {label ?? formField.label}{' '}
                  {formField.required && <span>*</span>}
                </FormLabel>
                {(description || formField.description) && (
                  <DescriptionTooltip
                    description={description ?? formField.description}
                  />
                )}
              </div>
              <FormControl>
                <div className='grid gap-1'>
                  <div className='flex items-center '>
                    <Input
                      className='rounded-r-none'
                      {...field}
                      onChange={(e) => onUpdate(e.target.value)}
                      value={currentValue ?? ''}
                      placeholder={placeholder ?? formField.label}
                      disabled={disabled ?? field.disabled ?? !canHandleField}
                    />

                    {area != null && (
                      <Button
                        disabled={disabled ?? field.disabled ?? !canHandleField}
                        variant='outline'
                        className={cn(
                          'w-[60px] min-w-[60px] max-w-[60px] whitespace-nowrap rounded-none  py-[13px] text-xs !leading-none ',
                          {
                            'opacity-50': [1, 3].includes(mode),
                          }
                        )}
                        type='button'
                        onClick={() => {
                          switch (mode) {
                            case 1:
                              setMode(2);
                              onUpdate(currentValue, 2);
                              break;
                            case 2:
                              setMode(1);
                              onUpdate(currentValue, 1);
                              break;
                            case 3:
                              setMode(4);
                              onUpdate(currentValue, 4);
                              break;
                            case 4:
                              setMode(3);
                              onUpdate(currentValue, 3);
                              break;
                          }
                        }}
                      >
                        /{area}m2
                      </Button>
                    )}
                    <Button
                      disabled={disabled ?? field.disabled ?? !canHandleField}
                      variant='secondary'
                      className='w-[60px] min-w-[60px] max-w-[60px] whitespace-nowrap rounded-sm rounded-l-none border border-input py-[13px]  text-xs !leading-none '
                      type='button'
                      onClick={() => {
                        switch (mode) {
                          case 1:
                            setMode(3);
                            onUpdate(currentValue, 3);
                            break;
                          case 2:
                            setMode(4);
                            onUpdate(currentValue, 4);
                            break;
                          case 3:
                            setMode(1);
                            onUpdate(currentValue, 1);
                            break;
                          case 4:
                            setMode(2);
                            onUpdate(currentValue, 2);
                            break;
                        }
                      }}
                    >
                      {[1, 2].includes(mode) ? '/år' : '/mån'}
                    </Button>
                  </div>
                  {renderInfo({ mode, currentValue, actualValue: field.value })}
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }
      }}
    />
  );
}
