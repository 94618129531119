import { Icons } from '@/components/icons';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { cn } from '@/lib/cn';
import { useGetList } from '@/requests/hooks';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/16/solid';
import type { BaseInstance } from '@pigello/pigello-matrix';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch, type Path } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import type { FieldProps } from '../types';
import { DescriptionTooltip } from './description-tooltip';

export function UniqueText<Instance extends BaseInstance = BaseInstance>({
  formField,
  name,
  placeholder,
  label,
  description,
  horizontal,
  className,
  disabled,
  canHandleField,
}: FieldProps<Instance>) {
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounceCallback(setSearch, 500);
  const config = formField.config;
  const { setError, clearErrors, formState } = useFormContext();
  const { dirtyFields, errors } = formState;
  const isFieldDirty = !!dirtyFields?.[formField.name as string];
  const { data, isLoading } = useGetList<Instance>({
    modelName: config.modelName,
    config: config,
    queryParams: {
      page: 1,
      pageSize: 1,
      filters: {
        [config.fields[formField.name].externalFieldName]: {
          __iexact: search.trim(),
        },
      },
    },
    enabled: !!search && isFieldDirty,
  });
  const value = useWatch({
    control: formField.control,
    name: name as Path<Instance>,
  });

  useEffect(() => {
    if (
      data?.meta.total_amount &&
      data?.meta.total_amount > 0 &&
      isFieldDirty &&
      search === value
    ) {
      setError(
        formField.name as string,
        {
          message: 'Detta värde är redan taget',
          type: 'unique',
        },
        {
          shouldFocus: true,
        }
      );
    } else {
      clearErrors(formField.name as string);
    }
  }, [
    clearErrors,
    data,
    formField.name,
    isFieldDirty,
    search,
    setError,
    value,
  ]);

  useEffect(() => {
    if (value) {
      debouncedSearch(value);
    }
  }, [name, value, debouncedSearch]);
  const isListEmpty =
    (!data?.list.length || data?.list?.length === 0) &&
    search === value &&
    search !== '';
  return (
    <FormField
      control={formField.control}
      rules={{
        required: formField.required && 'Detta fält är obligatoriskt',
      }}
      name={name as Path<Instance>}
      render={({ field }) => (
        <FormItem
          className={cn(
            'flex items-center justify-start',
            !horizontal && 'flex w-full flex-col items-start',
            className
          )}
        >
          <div className={cn(horizontal ? 'w-1/2 shrink-0' : 'w-full')}>
            <div className={cn('flex items-start')}>
              <FormLabel
                className={cn(formField.required && "after:content-['*']")}
              >
                {label ?? formField.label}{' '}
              </FormLabel>
              {(description || formField.description) && (
                <DescriptionTooltip
                  description={description ?? formField.description}
                />
              )}
            </div>
            {horizontal && <FormMessage />}
          </div>
          <FormControl>
            <div className='relative flex h-10 w-full items-center rounded-md border border-input leading-none shadow-input ring-offset-background  focus-within:outline-none focus-within:ring-1 focus-within:ring-ring focus-within:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50'>
              <input
                {...field}
                className='size-full rounded-sm border-0 bg-background px-3 py-2 text-sm shadow-none placeholder:text-muted-foreground/70 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50'
                onChange={(e) => {
                  field.onChange(e);
                }}
                value={field.value ?? ''}
                placeholder={placeholder ?? formField.label}
                disabled={disabled ?? field.disabled ?? !canHandleField}
              />
              {isLoading && (
                <Icons.loader className='absolute right-3 size-4 animate-spin' />
              )}
              {!isLoading && isListEmpty && (
                <CheckCircleIcon className='absolute right-3 size-4 text-green-600' />
              )}
              {!isLoading && errors?.[formField.name as string] && (
                <ExclamationCircleIcon className='absolute right-3 size-4 text-red-600' />
              )}
            </div>
          </FormControl>
          {!horizontal && <FormMessage />}
        </FormItem>
      )}
    />
  );
}
