'use client';

import { XMarkIcon } from '@heroicons/react/16/solid';
import * as React from 'react';

import { Badge } from '@/components/ui/badge';
import { Command, CommandList } from '@/components/ui/command';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { cn } from '@/lib/cn';
import type { BaseInstance } from '@pigello/pigello-matrix';
import { Command as CommandPrimitive } from 'cmdk';
import { motion } from 'framer-motion';
import type { Path } from 'react-hook-form';
import { toast } from 'sonner';
import type { FieldProps } from '../types';
import { DescriptionTooltip } from './description-tooltip';
type TData = Record<'v' | 'd', string>;

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  values?: TData[];
  onSelected?: (item: TData[]) => void;
};

const intRegex = /^(-[1-9][0-9]*|[0-9]+)?$/;

const checkIfFloorsIsValid = (floors: string) => {
  return intRegex.test(floors.trim());
};

export default function FloorField<
  Instance extends BaseInstance = BaseInstance,
>({
  formField,
  name,
  placeholder,
  label,
  description,
  className,
  horizontal,
  disabled,
  canHandleField,
}: FieldProps<Instance>) {
  if (horizontal) {
    return (
      <FormField
        rules={{
          required: formField.required && 'Detta fält är obligatoriskt',
        }}
        control={formField.control}
        name={name as Path<Instance>}
        render={({ field }) => (
          <FormItem
            className={cn('flex items-center justify-between', className)}
          >
            <div className='w-1/2 shrink-0'>
              <div className='flex items-center '>
                <FormLabel>
                  {label ?? formField.label}{' '}
                  {formField.required && <span>*</span>}
                </FormLabel>
                {(description || formField.description) && (
                  <DescriptionTooltip
                    description={description ?? formField.description}
                  />
                )}
              </div>

              <FormMessage />
            </div>
            <FormControl>
              <FloorFieldComp
                disabled={disabled ?? field.disabled ?? !canHandleField}
                onSelected={(val) =>
                  field.onChange(val.map((v) => parseInt(v.v)))
                }
                placeholder={placeholder ?? formField.label}
                values={field.value?.map((i: number) => ({
                  v: i.toString(),
                  d: i.toString(),
                }))}
              />
            </FormControl>
          </FormItem>
        )}
      />
    );
  }
  return (
    <FormField
      rules={{
        required: formField.required && 'Detta fält är obligatoriskt',
      }}
      control={formField.control}
      name={name as Path<Instance>}
      render={({ field }) => {
        return (
          <FormItem>
            <div className='flex items-center'>
              <FormLabel>
                {label ?? formField.label}{' '}
                {formField.required && <span>*</span>}
              </FormLabel>
              {(description || formField.description) && (
                <DescriptionTooltip
                  description={description ?? formField.description}
                />
              )}
            </div>
            <FormControl>
              <FloorFieldComp
                disabled={disabled ?? field.disabled ?? !canHandleField}
                onSelected={(val) =>
                  field.onChange(val.map((v) => parseInt(v.v)))
                }
                placeholder={placeholder ?? formField.label}
                values={field.value?.map((i: number) => ({
                  v: i.toString(),
                  d: i.toString(),
                }))}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}

export const FloorFieldComp = React.forwardRef<HTMLInputElement, InputProps>(
  ({ values, placeholder, onSelected, disabled }, ref) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const firstRender = React.useRef(true);
    // const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState<TData[]>([
      ...(values ?? []),
    ]);
    const [inputValue, setInputValue] = React.useState('');
    const handleUnselect = (item: TData) => {
      const newSelected = selected?.filter((s) => s.v !== item.v);
      setSelected(newSelected);
      onSelected?.(newSelected);
    };

    React.useEffect(() => {
      if (firstRender.current && values && values?.length > 0) {
        // Check if the floor is valid
        const validFloors = values
          .filter((floor) => checkIfFloorsIsValid(floor.v))
          .map((floor) => ({ v: floor.v, d: floor.v }));
        // Get the none valid floors
        const invalidFloors = values
          .filter((floor) => !checkIfFloorsIsValid(floor.v))
          .map((floor) => floor.v)
          .join(' ');
        setSelected(validFloors);
        setInputValue(invalidFloors);
        firstRender.current = false;
      }
    }, [values]);

    const handleAddFloors = React.useCallback(
      (text: string) => {
        const floors = text.split(',').filter((v) => v != '');
        // Check if the floor is valid
        const isAlreadySelected = selected?.find((floor) =>
          floors.includes(floor.v)
        );
        if (isAlreadySelected) {
          toast.error('Det finns redan en våning med detta våningsnummer');
          return;
        }
        const validFloors = floors.filter((floor) =>
          checkIfFloorsIsValid(floor)
        );
        // Get the none valid Floors
        const invalidFloors = floors
          .filter((floor) => !checkIfFloorsIsValid(floor))
          .join(' ');
        const newSelected = [
          ...selected,
          ...validFloors.map((floor) => ({ v: floor, d: floor })),
        ];
        setInputValue(invalidFloors);
        setSelected(newSelected);
        onSelected?.(newSelected);
      },
      [onSelected, selected]
    );

    const handleKeyDown = React.useCallback(
      (e: React.KeyboardEvent<HTMLDivElement>) => {
        const input = inputRef.current;
        if (input) {
          if (e.key === 'Delete' || e.key === 'Backspace') {
            if (input.value === '') {
              const newSelected = selected.slice(0, -1);
              setSelected(newSelected);
              onSelected?.(newSelected);
            }
          }
          if (e.key === ',' || e.key === ' ') {
            e.preventDefault();
          }
          if (
            (e.key === ',' || e.key === 'Tab' || e.key === ' ') &&
            checkIfFloorsIsValid(input.value)
          ) {
            handleAddFloors(input.value);
          }
          // This is not a default behaviour of the <input /> field
          if (e.key === 'Escape') {
            input.blur();
          }
        }
      },
      [handleAddFloors, onSelected, selected]
    );

    const handlePaste = React.useCallback(
      (e: React.ClipboardEvent<HTMLInputElement>) => {
        const input = inputRef.current;
        if (input) {
          e.preventDefault();
          const text = e.clipboardData.getData('text/plain');
          handleAddFloors(text);
        }
      },
      [handleAddFloors]
    );

    const onChange = (value: string) => {
      if (value === '-' || intRegex.test(value)) {
        setInputValue(value);
      }
    };

    return (
      <Command
        ref={ref}
        onKeyDown={handleKeyDown}
        className='overflow-visible bg-transparent'
      >
        <div className='group max-h-64 overflow-y-auto rounded-md border border-input px-3 py-2 text-sm shadow-input ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2'>
          <div className='flex flex-wrap gap-1'>
            {selected.map((item) => {
              return (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  key={`${item.v}-${item.d}`}
                >
                  <Badge
                    variant={'light-gray'}
                    className={cn(
                      'rounded-full bg-gray-200 text-foreground transition-all hover:bg-gray-200/80',
                      disabled && 'pointer-events-none opacity-50'
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {item.d}
                    <XMarkIcon
                      title='Rensa fältet'
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleUnselect(item);
                        }
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onClick={() => handleUnselect(item)}
                      className={cn(
                        'ml-1 size-4 transition-transform hover:rotate-90',
                        disabled && 'pointer-events-none opacity-50'
                      )}
                    />
                  </Badge>
                </motion.div>
              );
            })}
            <CommandPrimitive.Input
              ref={inputRef}
              value={inputValue}
              onValueChange={onChange}
              onPaste={handlePaste}
              onBlur={() => handleAddFloors(inputValue)}
              placeholder={selected.length > 0 ? '' : placeholder}
              disabled={disabled}
              className='flex-1 bg-transparent outline-none placeholder:text-muted-foreground disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50'
            />
          </div>
        </div>
        <CommandList />
      </Command>
    );
  }
);

FloorFieldComp.displayName = 'FloorField';
