export const PRIVATE_TENANT_BANKS = {
  'Danske Bank': 'OEB',
  SEB: 'SEB',
  Handelsbanken: 'SHB',
  Skandiabanken: 'SKB',
  Swedbank: 'FSPA',
  Nordea: 'NB',
  'Länsförsäk.bank': 'LFB',
  'Sparbanken Öresund': 'FINN',
  'ICA banken': 'ICA',
  'Sparbanken Syd': 'SYD',
  SBAB: 'SBAB',
  Ålandsbanken: 'AAB',
} as const;

export const COMPANY_TENANT_BANKS = {
  'Danske Bank': 'DBF',
  SEB: 'SEBF',
  Handelsbanken: 'SHBF',
  Nordea: 'NBF',
  Swedbank: 'SBF',
  'Länsförsäk.bank': 'LFBF',
} as const;

/*
 * fmi format for companies:
 * XXorgNumber.bankNumber.SE -  XX = 16 for SEB, 06 for Swedbank, 00 for other banks
 *
 * fmi format for private tennants:
 * personalNumber.bankNumber.SE
 * */
export function getBankFromFMI(fmi: string) {
  return fmi.split('.').at(1) ?? '';
}

export function createFMI(
  bank: string,
  identifier: string,
  isCompany: boolean
) {
  const parsedBank = bank || 'XXX';
  const parsedIdentifier =
    identifier || (isCompany ? 'XXXXXXXXXX' : 'XXXXXXXXXXXX');

  const prefix = bank === 'SEBF' ? '16' : bank === 'SBF' ? '06' : '00';

  if (isCompany) return `${prefix}${parsedIdentifier}.${parsedBank}.SE`;
  return `${parsedIdentifier}.${parsedBank}.SE`;
}
