import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { useManyPerms } from '@/hooks/usePerms';
import { cn } from '@/lib/cn';
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';
import { ContentTypeToName } from '@pigello/pigello-matrix';
import { useRef, useState } from 'react';
import type { Path } from 'react-hook-form';
import type { FieldProps } from '../types';
import { DescriptionTooltip } from './description-tooltip';

export default function ContentType<Instance extends BaseInstance>({
  formField,
  name,
  placeholder,
  label,
  description,
  horizontal,
  className,
  disabled,
  canHandleField,
}: FieldProps<Instance>) {
  const [open, setOpen] = useState(false);
  const btnRef = useRef<HTMLButtonElement | null>(null);

  const contentTypes = Object.keys(ContentTypeToName)
    ?.map((key) => ({
      v: key,
      d: ContentTypeToName[key as keyof typeof ContentTypeToName],
    }))
    .filter((key) => key.d !== 'CT_VERBOSE_NAME');

  const modelNames = contentTypes.map((c) => c.v.split('.')[1] as ModelName);

  const { perms: contentTypePerms } = useManyPerms(modelNames);

  const isNullable = formField.config.fields?.[name].nullable;

  const sortFn = (a: { v: string; d: string }, b: { v: string; d: string }) => {
    return a.d.toLocaleLowerCase().localeCompare(b.d.toLocaleLowerCase());
  };
  const choiceField = formField.config?.fields[formField.name];

  const contentTypeChoices =
    'choices' in choiceField ? choiceField.choices : contentTypes;

  const choices = contentTypeChoices?.filter((c) => {
    return contentTypePerms.find(
      (p) => p.modelName === (c.v.split('.')[1] as ModelName)
    )?.canView;
  });

  return (
    <FormField
      control={formField.control}
      rules={{
        required: formField.required && 'Detta fält är obligatoriskt',
      }}
      name={name as Path<Instance>}
      render={({ field }) => {
        return (
          <>
            <FormItem
              className={cn(
                'flex w-full flex-col items-center space-y-2',
                horizontal && 'flex-row justify-between',
                className
              )}
            >
              <div
                className={cn('grid', horizontal ? 'w-1/2 shrink-0' : 'w-full')}
              >
                <>
                  <FormLabel
                    className={cn(formField.required && 'after:content-["*"]')}
                  >
                    {label ?? formField.config?.fields[name].verboseName}{' '}
                  </FormLabel>
                  {(description || formField.description) && (
                    <DescriptionTooltip
                      description={description ?? formField.description}
                    />
                  )}
                </>
                {horizontal && <FormMessage />}
              </div>
              <div className='flex w-full items-center'>
                <Popover modal={true} open={open} onOpenChange={setOpen}>
                  <FormControl>
                    <PopoverTrigger asChild ref={btnRef}>
                      <Button
                        disabled={disabled ?? field.disabled ?? !canHandleField}
                        variant='outline'
                        ref={btnRef}
                        role='listbox'
                        className={cn(
                          'h-10 w-full items-center justify-between px-3 leading-relaxed shadow-input'
                        )}
                      >
                        {choices?.find((choice) => choice.v === field.value?.id)
                          ?.d ??
                          label ??
                          formField.label}
                        <div className='flex items-center'>
                          {field.value?.id !== '' && isNullable && (
                            <span
                              title='Rensa fältet'
                              onKeyDown={(evt) => {
                                if (evt.key === 'Enter') {
                                  evt.stopPropagation();
                                  evt.preventDefault();
                                  field.onChange({ id: '' });
                                }
                              }}
                              role='button'
                              tabIndex={0}
                              onClick={(evt) => {
                                evt.stopPropagation();
                                evt.preventDefault();
                                field.onChange({ id: '' });
                              }}
                            >
                              <XMarkIcon className='size-4 opacity-50 transition-opacity hover:opacity-100' />
                            </span>
                          )}
                          <ChevronUpDownIcon className='ml-2 size-4 shrink-0 opacity-50' />
                        </div>
                      </Button>
                    </PopoverTrigger>
                  </FormControl>
                  <PopoverContent
                    style={{ width: btnRef.current?.offsetWidth }}
                    className='p-0 pb-1'
                  >
                    <Command
                      loop={true}
                      filter={(value, search, keywords) => {
                        const extendValue = value + ' ' + keywords?.join(' ');
                        if (extendValue.includes(search)) return 1;
                        return 0;
                      }}
                    >
                      <CommandInput
                        placeholder={placeholder ?? formField.label}
                      />
                      <CommandList>
                        <CommandEmpty>Inga resultat hittades</CommandEmpty>
                        <CommandGroup className='max-h-60 w-auto overflow-y-scroll'>
                          {choices?.sort(sortFn).map((choice) => (
                            <CommandItem
                              keywords={[
                                choice?.d,
                                choice?.d?.toLowerCase(),
                                choice?.d?.toUpperCase(),
                              ]}
                              key={choice.v}
                              value={choice.v.toString()}
                              onSelect={() => {
                                field.onChange({ id: choice.v.toString() });
                                setOpen(false);
                              }}
                            >
                              <CheckIcon
                                className={cn(
                                  'mr-2 size-4',
                                  choice.v === field.value?.id
                                    ? 'opacity-100'
                                    : 'opacity-0'
                                )}
                              />
                              {choice.d}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
              </div>
              {!horizontal && <FormMessage className='self-start' />}
            </FormItem>
          </>
        );
      }}
    />
  );
}
