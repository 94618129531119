import { cn } from '@/lib/cn';
import * as React from 'react';

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    initial?: boolean;
  }
>(({ className, initial = true, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex flex-col gap-2 rounded-lg border bg-card p-4 text-card-foreground shadow-card',
      { 'animate-in fade-in slide-in-from-bottom-[10px]': initial },
      className
    )}
    {...props}
  />
));
Card.displayName = 'Card';

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex flex-col rounded-t-lg border-b bg-transparent pb-3',
      className
    )}
    {...props}
  />
));
CardHeader.displayName = 'CardHeader';

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <div className='flex items-center'>
    <h3
      ref={ref}
      className={cn('text-base font-medium leading-none', className)}
      {...props}
    >
      {children}
    </h3>
  </div>
));
CardTitle.displayName = 'CardTitle';

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  />
));
CardDescription.displayName = 'CardDescription';

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn(className)} {...props} />
));
CardContent.displayName = 'CardContent';

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('flex items-center', className)} {...props} />
));
CardFooter.displayName = 'CardFooter';

const CardContentRow = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex w-full justify-between py-2 first:pt-2', className)}
    {...props}
  />
));

CardContentRow.displayName = 'CardContentRow';

const CardContentRowTitle = React.forwardRef<
  HTMLSpanElement,
  React.HTMLAttributes<HTMLSpanElement>
>(({ className, ...props }, ref) => (
  <span
    ref={ref}
    className={cn(
      'text-sm font-normal leading-snug text-muted-foreground',
      className
    )}
    {...props}
  />
));

CardContentRowTitle.displayName = 'CardContentRowTitle';

export {
  Card,
  CardContent,
  CardContentRow,
  CardContentRowTitle,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
};
