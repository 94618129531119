import { deleteCookie } from 'cookies-next';
import type { ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies';
import type { NextRequest, NextResponse } from 'next/server';

type CookieOptions = {
  cookies?: () => ReadonlyRequestCookies;
  req?: NextRequest;
  res?: NextResponse;
};

export function clearCookies({
  cookies,
  req,
  res,
}: CookieOptions | undefined = {}) {
  deleteCookie('token', { cookies, req, res });
  deleteCookie('token_exp', { cookies, req, res });
  deleteCookie('organization_id', { cookies, req, res });
  deleteCookie('user_id', { cookies, req, res });
  deleteCookie('segments', { cookies, req, res });
  deleteCookie('branding', { cookies, req, res });
}
