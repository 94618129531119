'use client';

import { cn } from '@/lib/cn';
import { forwardRef } from 'react';

const Fieldset = forwardRef<
  React.ElementRef<'fieldset'>,
  React.ComponentPropsWithoutRef<'fieldset'>
>(({ className, ...props }, ref) => (
  <fieldset
    ref={ref}
    className={cn('col-span-full grid gap-6 rounded-lg border p-4', className)}
    {...props}
  />
));
Fieldset.displayName = 'Fieldset';

export { Fieldset };
