import { ObjectEntries } from '@/lib/utils';
import type { ErrorResponse } from '@/requests/types';
import type { BaseInstance } from '@pigello/pigello-matrix';
import { camelCase } from 'lodash';
import type { Path } from 'react-hook-form';
import type { useForm } from '.';

type Error = [string, [string]] | [string, Record<string, string>];

const removeNonDescriptiveKeys = (key: string) => {
  return key.replace(/(all|__unique__)/g, '');
};

export function handleFormErrors<Instance extends BaseInstance = BaseInstance>(
  error: ErrorResponse,
  form?: ReturnType<typeof useForm<Instance>>
) {
  try {
    if (!error.errorData) return `Error: ${error.status}`;
    const errors = ObjectEntries(error.errorData).map(([key, value]) => {
      if (typeof value === 'string') {
        return [key, [value]];
      }
      return [key, value];
    }) as Error[];
    const message = setErrors(errors, form);
    if (message?.key) {
      return `${
        form?.fields[message.key as keyof Instance]?.config.fields[
          message.key as keyof Instance
        ]?.verboseName ?? removeNonDescriptiveKeys(message.key)
      } ${message.value}`;
    }
    return message?.value;
  } catch (e) {
    console.info('Error parsing errors', e);
    return undefined;
  }
}

const setErrors = <Instance extends BaseInstance = BaseInstance>(
  errors: Error[],
  form?: ReturnType<typeof useForm<Instance>>
) => {
  const errorObj = { key: '', value: '' };
  const existsInForm = (key: string) => !!form?.fields[key as keyof Instance];
  let i = 0;
  for (const [key, error] of errors) {
    const field = camelCase(key) as Path<Instance>;
    if (key === '__unique__' && typeof error === 'object') {
      const errorField =
        typeof error === 'string'
          ? error
          : typeof error === 'object'
            ? Object.entries(error)
                .map(([k, v]) => `${k}: ${v}`)
                .join(', ')
            : error[0];
      const errorKey = camelCase(
        Object.keys(error)[0] ?? key
      ) as Path<Instance>;
      if (!existsInForm(errorKey)) {
        return { key: field, value: errorField };
      }
      form?.setError(errorKey, { message: errorField });
      form?.setFocus(errorKey);
      return { key: '', value: errorField };
    }

    if (typeof error === 'string') {
      form?.setError(field, { message: error });
      if (!errorObj.key) {
        errorObj.key = field;
        errorObj.value = error;
      }
    } else {
      for (const [errKey, errorValue] of Object.entries(error)) {
        const isNumberString = !isNaN(parseInt(errKey, 10));
        const nestedKey = isNumberString
          ? field
          : (`${field}.${camelCase(errKey)}` as Path<Instance>);
        if (existsInForm(nestedKey)) {
          form?.setError(nestedKey as Path<Instance>, { message: errorValue });
        }
        if (!errorObj.key) {
          errorObj.key = field;
          errorObj.value = errorValue;
        }
      }
    }
    if (i === 0) {
      form?.setFocus(field);
    }
    i++;
  }

  return errorObj;
};
