 import type { Address } from '@pigello/pigello-matrix';

import { AddressConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useCreateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, createGenericInstanceProps } from '@/requests/types';
import { createGenericInstance } from '@/requests/creating';

export const createAddress = async (options: createGenericInstanceProps<Address>) => {
  return createGenericInstance<Address>({
    ...options,
    config: AddressConfig,
  });
};

export const useCreateAddress = (
  options: UseMutationOptions<Address, ErrorResponse, unknown, unknown>
) => useCreateGenericInstance<Address>(createAddress, options, 'address');
