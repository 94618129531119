'use client';

import { cn } from '@/lib/cn';
import { forwardRef } from 'react';

const Legend = forwardRef<
  React.ElementRef<'legend'>,
  React.ComponentPropsWithoutRef<'legend'>
>(({ className, ...props }, ref) => (
  <legend
    ref={ref}
    className={cn(
      '-ml-1 whitespace-nowrap px-1 text-sm font-medium',
      className
    )}
    {...props}
  />
));
Legend.displayName = 'Legend';

export { Legend };
