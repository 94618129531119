import { clearCookies } from '@/lib/clear-cookies';
import { ObjectEntries } from '@/lib/utils';
import type { FieldPermKey, Self, UnpackedPermissions } from '@/store/types';
import type { ContentType, ModelName } from '@pigello/pigello-matrix';
import { Configs } from '@pigello/pigello-matrix';
import { fetchApi } from './api';
import { BASE_BACKEND_URL } from './constants';

export async function getSelf() {
  const res = await fetchApi({
    url: `${BASE_BACKEND_URL}/accounts/users/profile/`,
  });

  if (!res.ok) {
    clearCookies();
    throw {
      status: res.status,
      message: res.statusText,
      url: res.url,
    };
  }

  const data = await res.json();

  if (data.user_type === 'accounts.tenant') {
    const tenantData = await fetchApi({
      url: `${BASE_BACKEND_URL}/accounts/users/customer/tenant/${data.id}/`,
    });

    const dataAsCamelCase = ObjectEntries({ ...data, ...tenantData }).reduce<
      Record<string, unknown>
    >((acc, [key, value]) => {
      acc[key.replace(/_([a-z])/g, (g) => g[1].toUpperCase())] = value;
      return acc;
    }, {}) as Self;

    return dataAsCamelCase;
  }

  const permissions = unpackPerms(data.permissions);

  // Convert snake_case to camelCase -- Special case for self.data
  const dataAsCamelCase = ObjectEntries(data).reduce<Record<string, unknown>>(
    (acc, [key, value]) => {
      acc[key.replace(/_([a-z])/g, (g) => g[1].toUpperCase())] = value;
      return acc;
    },
    {}
  ) as Self;

  return {
    ...dataAsCamelCase,
    permissions,
  };
}
type ConfiguredAccessEntities = {
  [key: ContentType]: {
    affected_attributes: {
      [key: string]: {
        [key: string]: boolean;
        can_view: boolean;
        can_handle: boolean;
      };
    };
    affected_methods: {
      can_create: boolean;
      can_delete: boolean;
      can_update: boolean;
      can_view: boolean;
      [key: string]: boolean;
    };
  };
};
export type PackedPermissions = {
  all_regulated_models: ContentType[];
  pricing_disallowed_models: ContentType[];
  role: {
    unconfigured_models: ContentType[];
    configured_access_entities: ConfiguredAccessEntities[];
  };
};

function unpackPerms(permissions: PackedPermissions): UnpackedPermissions {
  const returnBoi = {} as UnpackedPermissions;
  const allRegulatedModels = permissions.all_regulated_models.map(
    (contentType) => contentType.split('.')[1] as ModelName
  );
  const pricingDisallowedModels = permissions.pricing_disallowed_models.map(
    (contentType) => contentType.split('.')[1] as ModelName
  );
  const unConfiguredModels = permissions.role.unconfigured_models.map(
    (contentType) => contentType.split('.')[1] as ModelName
  );
  const configuredAccessEntities = unpackConfiguredAccessEntities(
    permissions.role.configured_access_entities
  );

  let modelName: ModelName;
  for (modelName in Configs) {
    if (pricingDisallowedModels.includes(modelName)) {
      returnBoi[modelName] = {
        overall: {
          canView: false,
          canDelete: false,
          canCreate: false,
          canUpdate: false,
        },
        fields: {},
      };
      continue;
    }

    if (!allRegulatedModels.includes(modelName)) {
      returnBoi[modelName] = {
        overall: {
          canView: true,
          canDelete: true,
          canCreate: true,
          canUpdate: true,
        },
        fields: {},
      };
      continue;
    }
    if (unConfiguredModels.includes(modelName)) {
      returnBoi[modelName] = {
        overall: {
          canView: false,
          canDelete: false,
          canCreate: false,
          canUpdate: false,
        },
        fields: {},
      };
      continue;
    }
    returnBoi[modelName] = configuredAccessEntities[modelName];
  }

  return returnBoi;
}

type UnpackedConfiguredAccessEntities = {
  [key in ModelName]: {
    fields: {
      [key: string]: {
        canView: boolean;
        canHandle: boolean;
        [key: FieldPermKey]: boolean;
      };
    };
    overall: {
      canView: boolean;
      canDelete: boolean;
      canCreate: boolean;
      canUpdate: boolean;
    };
  };
};
function unpackConfiguredAccessEntities(
  configured_access_entities: ConfiguredAccessEntities[]
): UnpackedConfiguredAccessEntities {
  const configuredAccessEntities = {} as UnpackedConfiguredAccessEntities;
  for (const accessCollection of configured_access_entities) {
    let contentType: ContentType;
    for (contentType in accessCollection) {
      const modelName = contentType.split('.')[1] as ModelName;
      const fields = accessCollection[contentType].affected_attributes;
      const methods = accessCollection[contentType].affected_methods;

      // the perms are any, so if we already have an existing with "true" then its true
      configuredAccessEntities[modelName] = {
        overall: {
          canView:
            configuredAccessEntities[modelName]?.overall?.canView ||
            methods.can_view,
          canDelete:
            configuredAccessEntities[modelName]?.overall?.canDelete ||
            methods.can_delete,
          canCreate:
            configuredAccessEntities[modelName]?.overall?.canCreate ||
            methods.can_create,
          canUpdate:
            configuredAccessEntities[modelName]?.overall?.canUpdate ||
            methods.can_update,
        },
        fields: configuredAccessEntities[modelName]?.fields ?? {},
      };
      for (const fieldName in fields) {
        const usedFieldName = fieldName
          .replace(/_([a-z])/g, (g) => g[1].toUpperCase())
          .replace('_', '');

        configuredAccessEntities[modelName].fields[usedFieldName] = {
          canView:
            configuredAccessEntities[modelName]?.fields?.[usedFieldName]
              ?.canView || fields[fieldName].can_view,
          canHandle:
            configuredAccessEntities[modelName]?.fields?.[usedFieldName]
              ?.canHandle || fields[fieldName].can_handle,
        };
      }
    }
  }

  return configuredAccessEntities;
}
