import { getSelf } from '@/requests/getSelf';
import type { ErrorResponse } from '@/requests/types';
import type { Self } from '@/store/types';
import { datadogRum } from '@datadog/browser-rum';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { getCookies } from 'cookies-next';
import { useEffect } from 'react';

export const useGetSelfQueryKey = 'self';

export const useGetSelf = (
  options?: Omit<
    UseQueryOptions<unknown, ErrorResponse, Self, string[]>,
    'queryKey' | 'queryFn' | 'staleTime' | 'refetchOnWindowFocus' | 'enabled'
  >
) => {
  const cookies = getCookies();

  const self = useQuery({
    queryKey: [useGetSelfQueryKey],
    queryFn: getSelf,
    // 3 minutes
    staleTime: 60 * 3 * 1000,
    refetchOnWindowFocus: true,
    retry: false,
    enabled: !!cookies?.token,
    ...options,
  });

  useEffect(() => {
    if (self.data) {
      const id = `${cookies?.customer_id}_${self.data?.id}`;
      datadogRum.setUser({
        id,
        name: self.data?.username,
        px_customer_id: cookies?.customer_id,
        px_user_id: self.data?.id,
      });
    }
  }, [cookies?.customer_id, self.data]);

  return self;
};
