import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { TextArea } from '@/components/ui/textarea';
import { cn } from '@/lib/cn';
import type { BaseInstance } from '@pigello/pigello-matrix';
import type { Path } from 'react-hook-form';
import type { FieldProps } from '../types';

type TextAreaFieldProps = {
  resize?: boolean;
  rows?: number;
  cols?: number;
};

export function Textarea<Instance extends BaseInstance = BaseInstance>({
  formField,
  name,
  placeholder,
  label,
  description,
  horizontal,
  className,
  resize,
  rows = 4,
  cols = 50,
  disabled,
  canHandleField,
}: FieldProps<Instance> & TextAreaFieldProps) {
  if (horizontal) {
    return (
      <FormField
        rules={{
          required: formField.required && 'Detta fält är obligatoriskt',
        }}
        control={formField.control}
        name={name as Path<Instance>}
        render={({ field }) => (
          <FormItem
            className={cn('flex items-center justify-between', className)}
          >
            <div className='w-1/2 shrink-0'>
              <FormLabel>
                {label ?? formField.label}{' '}
                {formField.required && <span>*</span>}
              </FormLabel>
              <FormDescription>
                {description ?? formField?.description}
              </FormDescription>
              <FormMessage />
            </div>
            <FormControl>
              <TextArea
                {...field}
                value={field.value ?? ''}
                className={
                  resize
                    ? ''
                    : 'resize-none placeholder:text-muted-foreground/70'
                }
                placeholder={placeholder ?? formField.label}
                rows={rows}
                cols={cols}
                disabled={disabled ?? field.disabled ?? !canHandleField}
              />
            </FormControl>
          </FormItem>
        )}
      />
    );
  }
  return (
    <FormField
      rules={{
        required: formField.required && 'Detta fält är obligatoriskt',
      }}
      control={formField.control}
      name={name as Path<Instance>}
      render={({ field }) => (
        <FormItem className={className}>
          <FormLabel>
            {label ?? formField.label} {formField.required && <span>*</span>}
          </FormLabel>
          <FormControl>
            <TextArea
              {...field}
              className={
                resize ? '' : 'resize-none placeholder:text-muted-foreground/70'
              }
              placeholder={placeholder ?? formField.label}
              rows={rows}
              cols={cols}
              disabled={disabled ?? field.disabled ?? !canHandleField}
            />
          </FormControl>
          <FormDescription>
            {description ?? formField?.description}
          </FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
