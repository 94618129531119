'use client';

import type { BaseInstance } from '@pigello/pigello-matrix';

import { usePerms } from '@/hooks/usePerms';
import { useFormContext } from 'react-hook-form';
import { AbstractObjectField } from './components/abstract-object-field';
import { AccountNumber } from './components/account-number-field';
import { AddressField } from './components/address-field';
import { Array } from './components/array-field';
import { Boolean } from './components/boolean-field';
import { ChoiceField } from './components/choice-field';
import ContentType from './components/content-type';
import { DateField } from './components/date-field';
import { DistributionInstruction } from './components/distribution-instruction-field';
import File from './components/file-field';
import FloorField from './components/floor-field';
import { FMIField } from './components/fmi-field';
import { IBANAccount } from './components/iban-field';
import { DynamicFieldLoader } from './components/loaders';
import ManyRelationalField from './components/many-relation';
import { NumberField } from './components/number-field';
import { PriceField } from './components/price-field';
import RelationField from './components/relation-field';
import { TextField } from './components/text-field';
import { Textarea } from './components/textarea-field';
import { UniqueText } from './components/unique-text-field';
import { WeekField } from './components/week-field';
import type { FieldProps } from './types';
import { containsOrderedField } from './utils';
/**
 * @description
 * This is a component that renders a form field.
 * @param name - Deprecated: Path name to the form field value. Only use formField.fields['nameOfYourField'] instead.
 * @param label - Label to display for the form field. Overrides the config label.
 * @param placeholder - Placeholder to display for the form field.
 * @param description - Description to display for the form field. Overrides the config description.
 * @param horizontal - Whether to display the form field horizontally. Vertical by default.
 * @param className - Class name to apply to the form field. Adds classNames to the ```<FormItem />``` component.
 * @param fieldType ```('string' | 'number')``` - Type of the form field. Defaults to 'string'. Only applies to ```<ChoiceField />```.
 * @example
 * ```tsx
 *  <DynamicField
      formField={form.fields['nameOfYourField']}
      name='name'
      label='Label'
      placeholder='Placeholder'
      description='A description'
      horizontal={true}
      className='my-class-name'
    />
 * ```
 */

const addressSpecialCases = [
  'includeApartmentIdInAddress',
  'includeCompanyAddress',
  'customPostalAddress',
];

export function DynamicField<Instance extends BaseInstance = BaseInstance>({
  name,
  formField,
  placeholder,
  label,
  description,
  horizontal = false,
  className,
  fieldType,
  fileFieldStyle = 'dropzone',
  fileFieldAccept,
  isLoading,
  disabled,
  btnLabel,
  filters,
  area,
  resize,
  rows,
  cols,
  overrideRequired,
}: FieldProps<Instance>) {
  const { fields } = usePerms<Instance>(formField.config?.modelName);
  const formContext = useFormContext();

  const canHandleField =
    fields[formField.name]?.canHandle === true ||
    fields[formField.name]?.canHandle === undefined;

  if (formField.readOnly || formField.name === 'tags') {
    return null;
  }

  if (isLoading) {
    return (
      <DynamicFieldLoader
        {...{
          className,
          horizontal,
          type: formField.type,
        }}
      />
    );
  }

  if (
    formField.name.toString().toLowerCase().includes('address') &&
    !addressSpecialCases.includes(formField.name.toString())
  ) {
    return (
      <AddressField
        {...{
          formField,
          name: formField.name,
          placeholder,
          label,
          description,
          horizontal,
          className,
          disabled,
          canHandleField,
        }}
      />
    );
  }

  // In cases were we for some reason don't have ordered fields with the fmi
  // field, we fallback to the default field
  if (
    formField.name.toString().toLowerCase().includes('fmi') &&
    containsOrderedField<Instance>(formContext)
  ) {
    return (
      <FMIField
        {...{
          formField,
          name: formField.name,
          placeholder,
          label,
          description,
          horizontal,
          className,
          disabled,
          canHandleField,
          orderedFields: formContext.orderedFields,
        }}
      />
    );
  }

  switch (formField.type) {
    case 'array': {
      if (
        formField.name.toString().includes('floors') ||
        formField.name.toString().includes('excludeForAccounts')
      ) {
        return (
          <FloorField
            {...{
              formField,

              name: formField.name,
              placeholder,
              label,
              description,
              horizontal,
              className,
              disabled,
              canHandleField,
            }}
          />
        );
      }

      return (
        <Array
          {...{
            overrideRequired,
            formField,
            name: formField.name,
            placeholder,
            label,
            description,
            horizontal,
            className,
            disabled,
            canHandleField,
          }}
        />
      );
    }

    case 'weektext': {
      return (
        <WeekField
          {...{
            formField,
            name: formField.name,
            placeholder,
            label,
            description,
            horizontal,
            className,
            disabled,
            canHandleField,
          }}
        />
      );
    }

    case 'text': {
      return (
        <TextField
          {...{
            formField,
            name: formField.name,
            placeholder,
            label,
            description,
            horizontal,
            className,
            isLoading,
            disabled,
            canHandleField,
          }}
        />
      );
    }

    case 'uniquetext': {
      return (
        <UniqueText
          {...{
            formField,
            name: formField.name,
            placeholder,
            label,
            description,
            horizontal,
            className,
            isLoading,
            disabled,
            canHandleField,
          }}
        />
      );
    }

    case 'textarea': {
      return (
        <Textarea
          {...{
            formField,
            name: formField.name,
            placeholder,
            label,
            description,
            horizontal,
            className,
            disabled,
            canHandleField,
            resize,
            rows,
            cols,
          }}
        />
      );
    }

    case 'pricefield': {
      return (
        <PriceField
          {...{
            area,
            formField,
            name: formField.name,
            placeholder,
            label,
            description,
            horizontal,
            className,
            isLoading,
            disabled,
            canHandleField,
          }}
        />
      );
    }

    case 'number': {
      return (
        <NumberField
          {...{
            formField,
            name: formField.name,
            placeholder,
            label,
            description,
            horizontal,
            className,
            isLoading,
            disabled,
            canHandleField,
          }}
        />
      );
    }
    case 'accountnumber': {
      return (
        <AccountNumber
          {...{
            formField,
            name: formField.name,
            placeholder,
            label,
            description,
            horizontal,
            className,
            isLoading,
            canHandleField,
          }}
        />
      );
    }
    case 'ibanfield': {
      return (
        <IBANAccount
          {...{
            formField,
            name: formField.name,
            placeholder,
            label,
            description,
            horizontal,
            className,
            isLoading,
            canHandleField,
          }}
        />
      );
    }

    case 'dateTime': {
      return (
        <DateField
          {...{
            granularity: 'minute',
            formField,
            name: formField.name,
            placeholder,
            label,
            description,
            horizontal,
            className,
            disabled,
            canHandleField,
          }}
        />
      );
    }
    case 'date': {
      return (
        <DateField
          {...{
            formField,
            name: formField.name,
            placeholder,
            label,
            description,
            horizontal,
            className,
            disabled,
            canHandleField,
          }}
        />
      );
    }

    case 'boolean': {
      return (
        <Boolean
          {...{
            formField,
            name: formField.name,
            label,
            description,
            placeholder,
            className,
            horizontal,
            isLoading,
            disabled,
            canHandleField,
          }}
        />
      );
    }

    case 'contentType': {
      return (
        <ContentType
          {...{
            formField,
            name: formField.name,
            label,
            description,
            placeholder,
            className,
            horizontal,
            isLoading,
            disabled,
            canHandleField,
          }}
        />
      );
    }

    case 'manyrelation': {
      return (
        <ManyRelationalField
          {...{
            filters,
            formField,
            name: formField.name,
            label,
            description,
            placeholder,
            className,
            horizontal,
            disabled,
            canHandleField,
          }}
        />
      );
    }

    case 'relation': {
      return (
        <RelationField
          {...{
            overrideRequired,
            filters,
            formField,
            name: formField.name,
            label,
            description,
            placeholder,
            className,
            horizontal,
            isLoading,
            disabled,
            canHandleField,
          }}
        />
      );
    }

    case 'choice': {
      return (
        <ChoiceField
          {...{
            formField,
            name: formField.name,
            placeholder,
            label,
            className,
            description,
            horizontal,
            fieldType,
            isLoading,
            disabled,
            canHandleField,
            overrideRequired,
          }}
        />
      );
    }

    case 'fileField': {
      return (
        <File
          {...{
            formField,
            name: formField.name,
            placeholder,
            label,
            className,
            description,
            horizontal,
            fieldType,
            fileFieldStyle,
            disabled,
            canHandleField,
            fileFieldAccept,
          }}
        />
      );
    }

    case 'distributionInstruction': {
      return (
        <DistributionInstruction
          {...{
            formField,
            name: formField.name,
            placeholder,
            label,
            className,
            description,
            horizontal,
            fieldType,
            fileFieldStyle,
            disabled,
            canHandleField,
          }}
        />
      );
    }

    case 'record': {
      return (
        <AbstractObjectField
          {...{
            formField,
            name: formField.name,
            placeholder,
            label,
            description,
            horizontal,
            className,
            disabled,
            canHandleField,
            btnLabel,
          }}
        />
      );
    }

    default: {
      console.log('Unknown input type: ', name);
      return (
        <>
          <div className='text-red-500'>
            Unknown input type: {formField.name.toString()}
          </div>
        </>
      );
    }
  }
}
