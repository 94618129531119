import { raise } from '@/lib/utils';
import type {
  BaseInstance,
  IBaseInstanceConfig,
  ModelName,
} from '@pigello/pigello-matrix';
import { getConfig } from '@pigello/pigello-matrix';
import * as api from './api/post';
import { toExternalFieldNames, toInternalFieldNames } from './instanceMapper';

export const createGenericInstance = async <Instance extends BaseInstance>({
  modelName,
  config,
  body,
  options,
}: {
  modelName?: ModelName;
  config?: IBaseInstanceConfig<Instance>;
  body: Partial<Instance>;
  options?: {
    convertRelations?: boolean;
    skipOrganizationOnExternalFieldNames?: string[];
  };
}) => {
  if (!config && !modelName) raise('No config or modelname');
  const actualConfig = config ? config : await getConfig<Instance>(modelName!);
  const url = actualConfig.instanceUrl;
  const bodyToSend = (await toExternalFieldNames<Instance>(
    actualConfig,
    body,
    options?.convertRelations
  )) as Partial<Instance>;
  const res = await api.post<Instance>({
    url,
    body: bodyToSend,
    skipOrganizationOnExternalFieldNames:
      options?.skipOrganizationOnExternalFieldNames,
  });

  return toInternalFieldNames(actualConfig, res.data);
};
