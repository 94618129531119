import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';
import { useMemo } from 'react';
import { useGetSelf } from './useGetSelf';

type FieldKey = 'canView' | 'canHandle';

type Fields<Instance extends BaseInstance> = {
  [key in keyof Instance]: {
    [key in FieldKey]: boolean;
  };
};

export type Perms<Instance extends BaseInstance> = {
  canCreate: boolean;
  canView: boolean;
  canDelete: boolean;
  canUpdate: boolean;
  fields: Fields<Instance>;
};

export function usePerms<Instance extends BaseInstance = BaseInstance>(
  modelName?: ModelName
) {
  const { data: self, isPending } = useGetSelf();
  const perms = useMemo<Perms<Instance>>(() => {
    if (!modelName || !self?.permissions) {
      return {
        canCreate: false,
        canUpdate: false,
        canView: false,
        canDelete: false,
        fields: {} as Fields<Instance>,
      };
    }
    const allPerms = self.permissions[modelName];
    return {
      ...allPerms?.overall,
      fields: allPerms?.fields as {
        [key in keyof Instance]: {
          [key: `can${string}`]: boolean;
          canView: boolean;
          canHandle: boolean;
        };
      },
    };
  }, [modelName, self?.permissions]);

  const getField = (fieldName: keyof Instance, key: FieldKey) => {
    if (Object.keys(perms.fields).length === 0 && isPending) return false;

    if (Object.keys(perms.fields).length === 0 && !isPending) return true;

    return perms.fields?.[fieldName]?.[key];
  };

  return { isPending, getField, ...perms };
}

export function useManyPerms(modelNames: ModelName[]) {
  const { data: self, isPending: isFetchingPerms } = useGetSelf();
  const perms = useMemo(() => {
    if (modelNames.length === 0 || !self?.permissions) return;
    const perms = modelNames.map((modelName) => {
      const allPerms = self?.permissions[modelName];
      return {
        ...allPerms?.overall,
        modelName,
      };
    });
    return perms;
  }, [modelNames, self?.permissions]);

  return {
    isFetchingPerms,
    canViewAll: perms ? perms.every((p) => p.canView) : false,
    canCreateAll: perms ? perms.every((p) => p.canCreate) : false,
    canUpdateAll: perms ? perms.every((p) => p.canUpdate) : false,
    canDeleteAll: perms ? perms.every((p) => p.canDelete) : false,
    canViewAny: perms ? perms.some((p) => p.canView) : false,
    canCreateAny: perms ? perms.some((p) => p.canCreate) : false,
    canUpdateAny: perms ? perms.some((p) => p.canUpdate) : false,
    canDeleteAny: perms ? perms.some((p) => p.canDelete) : false,
    perms:
      perms ??
      modelNames.map((modelName) => ({
        modelName,
        canView: false,
        canUpdate: false,
        canDelete: false,
        canCreate: false,
      })),
  };
}
