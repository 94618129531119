'use client';

import { XMarkIcon } from '@heroicons/react/16/solid';
import * as React from 'react';

import { Badge } from '@/components/ui/badge';
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { PopoverAnchor } from '@radix-ui/react-popover';
import { Command as CommandPrimitive } from 'cmdk';
import { motion } from 'framer-motion';
import { Popover, PopoverContent } from './popover';

export type TData = Record<'v' | 'd', string | number>;

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  values?: TData[];
  defaultValues: TData[];
  onSelected?: (item: TData[]) => void;
};

export const MultiSelectInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ values, placeholder, onSelected, defaultValues, disabled }, ref) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const firstRender = React.useRef(true);
    const [open, setOpen] = React.useState(false);
    const inputBoxRef = React.useRef<HTMLDivElement>(null);
    const [selected, setSelected] = React.useState<TData[]>([
      ...(values ?? []),
    ]);
    const [inputValue, setInputValue] = React.useState('');
    const handleUnselect = (item: TData) => {
      const newSelected = selected?.filter((s) => s.v !== item.v);
      setSelected(newSelected);
      onSelected?.(newSelected);
    };

    React.useEffect(() => {
      if (firstRender.current && values && values?.length > 0) {
        setSelected(values);
        firstRender.current = false;
      }
    }, [values]);

    const handleKeyDown = React.useCallback(
      (e: React.KeyboardEvent<HTMLDivElement>) => {
        const input = inputRef.current;
        if (input) {
          if (e.key === 'Delete' || e.key === 'Backspace') {
            if (input.value === '') {
              const newSelected = selected.slice(0, -1);
              setSelected(newSelected);
              onSelected?.(newSelected);
            }
          }
          // This is not a default behavior of the <input /> field
          if (e.key === 'Escape') {
            input.blur();
          }
        }
      },
      [onSelected, selected]
    );

    // Check if the item is already selected from defaultValue and remove it from the list of items
    const selectables = React.useMemo(() => {
      return defaultValues?.filter((val) => {
        return !selected.find((s) => s.v === val.v);
      });
    }, [defaultValues, selected]);

    return (
      <Popover
        modal
        open={open && selectables?.length > 0}
        onOpenChange={setOpen}
      >
        <Command
          ref={ref}
          onKeyDown={handleKeyDown}
          className='overflow-visible bg-transparent'
        >
          <PopoverAnchor asChild>
            <div
              ref={inputBoxRef}
              className='group max-h-64 overflow-y-auto rounded-md border border-input px-3 py-2 text-sm shadow-input ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2'
            >
              <div className='flex flex-wrap gap-1'>
                {selected?.map((item) => {
                  return (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      key={`${item.v}-${item.d}-selected`}
                    >
                      <Badge
                        variant={'light-gray'}
                        className='rounded-full bg-gray-200 text-foreground transition-all hover:bg-gray-200/80'
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {item.d}
                        <XMarkIcon
                          title='Rensa fältet'
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleUnselect(item);
                            }
                          }}
                          role='button'
                          tabIndex={0}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUnselect(item);
                          }}
                          className='ml-1 size-4 transition-transform hover:rotate-90'
                        />
                      </Badge>
                    </motion.div>
                  );
                })}
                {/* Avoid having the "Search" Icon */}

                <CommandPrimitive.Input
                  disabled={disabled}
                  ref={inputRef}
                  value={inputValue}
                  onValueChange={setInputValue}
                  onBlur={() => setOpen(false)}
                  onFocus={() => setOpen(true)}
                  placeholder={placeholder}
                  className='ml-2 flex-1 bg-transparent outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50'
                />
              </div>
            </div>
          </PopoverAnchor>
          <PopoverContent
            className='p-0'
            style={{
              width: inputBoxRef.current?.offsetWidth,
            }}
            onOpenAutoFocus={(e) => {
              e.preventDefault();
              inputRef.current?.focus();
            }}
          >
            <CommandList>
              <CommandGroup className='h-full overflow-auto'>
                {selectables.map((item) => {
                  return (
                    <CommandItem
                      key={`${item.v}-${item.d}-selectable`}
                      onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onSelect={() => {
                        setInputValue('');
                        const newSelected = [...selected, item];
                        setSelected(newSelected);
                        onSelected?.(newSelected);
                      }}
                      className={'cursor-pointer'}
                    >
                      {item.d}
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </CommandList>
          </PopoverContent>
        </Command>
      </Popover>
    );
  }
);

MultiSelectInput.displayName = 'MultiSelectInput';
