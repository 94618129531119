import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/cn';
import { ChevronDownIcon } from '@heroicons/react/16/solid';
import type { BaseInstance } from '@pigello/pigello-matrix';
import { useState } from 'react';
import type { Path } from 'react-hook-form';
import { toast } from 'sonner';
import type { FieldProps } from '../types';
import { DescriptionTooltip } from './description-tooltip';

export function WeekField<Instance extends BaseInstance = BaseInstance>({
  formField,
  name,
  placeholder,
  label,
  description,
  horizontal,
  className,
  disabled,
  canHandleField,
}: FieldProps<Instance>) {
  const [mode, setMode] = useState<'months' | 'weeks'>('months');
  const [hasSetInitial, setHasSetInitial] = useState(false);

  return (
    <FormField
      rules={{
        required: formField.required && 'Detta fält är obligatoriskt',
      }}
      control={formField.control}
      name={name as Path<Instance>}
      render={({ field }) => {
        if (!hasSetInitial) {
          if (field.value && field.value < 4) {
            setMode('weeks');
            setHasSetInitial(true);
          }
        }

        const renderedValue = mode === 'months' ? field.value / 4 : field.value;

        const handleUpdateValue = (value: string) => {
          const parsedVal = isNaN(parseInt(value)) ? 0 : parseInt(value);
          if (mode === 'months') {
            field.onChange({ target: { value: parsedVal * 4 } });
          } else {
            field.onChange({
              target: { value: parsedVal > 3 ? 3 : parsedVal },
            });

            if (parsedVal > 3) {
              toast.info(
                'Max antal veckor är 3. Använd månader istället för längre tidsperioder.'
              );
            }
          }
        };

        if (horizontal) {
          return (
            <FormItem
              className={cn('flex items-center justify-between', className)}
            >
              <div className='w-1/2 shrink-0'>
                <div className='flex items-center'>
                  <FormLabel>
                    {label ?? formField.label}
                    {formField.required && <span>*</span>}
                  </FormLabel>

                  {(description || formField.description) && (
                    <DescriptionTooltip
                      description={description ?? formField.description}
                    />
                  )}
                </div>

                <FormMessage />
              </div>
              <FormControl>
                <div className='flex flex-1 items-center'>
                  <Input
                    className='flex-1'
                    {...field}
                    onChange={({ target: { value } }) => {
                      handleUpdateValue(value);
                    }}
                    value={renderedValue ?? ''}
                    placeholder={placeholder ?? formField.label}
                    disabled={disabled ?? field.disabled ?? !canHandleField}
                  />
                  <DropdownMenu>
                    <DropdownMenuTrigger
                      asChild
                      disabled={disabled ?? field.disabled ?? !canHandleField}
                    >
                      <Button variant='link'>
                        {mode === 'months' ? 'Månader' : 'Veckor'}{' '}
                        <ChevronDownIcon className='ml-2 size-4' />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem
                        onClick={() => {
                          field.onChange({ target: { value: 0 } });

                          setMode('months');
                        }}
                      >
                        Månader
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          field.onChange({ target: { value: 0 } });
                          setMode('weeks');
                        }}
                      >
                        Veckor
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </FormControl>
            </FormItem>
          );
        } else {
          return (
            <FormField
              control={formField.control}
              rules={{
                required: formField.required && 'Detta fält är obligatoriskt',
              }}
              name={name as Path<Instance>}
              render={({ field }) => (
                <FormItem className={className}>
                  <div className='flex items-center'>
                    <FormLabel>
                      {label ?? formField.label}{' '}
                      {formField.required && <span>*</span>}
                    </FormLabel>
                    {(description || formField.description) && (
                      <DescriptionTooltip
                        description={description ?? formField.description}
                      />
                    )}
                  </div>
                  <FormControl>
                    <div className='flex flex-1 items-center'>
                      <Input
                        {...field}
                        className='flex-1'
                        onChange={({ target: { value } }) => {
                          handleUpdateValue(value);
                        }}
                        value={renderedValue ?? ''}
                        placeholder={placeholder ?? formField.label}
                        disabled={disabled ?? field.disabled ?? !canHandleField}
                      />
                      <DropdownMenu>
                        <DropdownMenuTrigger
                          asChild
                          disabled={
                            disabled ?? field.disabled ?? !canHandleField
                          }
                        >
                          <Button variant='link'>
                            {mode === 'months' ? 'Månader' : 'Veckor'}{' '}
                            <ChevronDownIcon className='ml-2 size-4' />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuItem
                            onClick={() => {
                              field.onChange({ target: { value: 0 } });

                              setMode('months');
                            }}
                          >
                            Månader
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={() => {
                              field.onChange({ target: { value: 0 } });
                              setMode('weeks');
                            }}
                          >
                            Veckor
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          );
        }
      }}
    />
  );
}
